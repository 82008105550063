<template>
  <header
      class="navbar ps-4 pe-4 ">
    <router-link
        :class="`navbar-brand col-auto ${$store.getters.company.logo} ${$store.getters.sidebar_state?'collapse':''}`"
        to="/">
    </router-link>

    <template v-if="$store.getters.isLoggedIn">

      <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">

        <!--            <li>-->
        <!--              <a href="#" v-on:click.prevent="" title="Тариф" class="nav-link text-white">-->
        <!--                Тариф "{{user.tariff}}"-->
        <!--              </a>-->
        <!--            </li>-->
        <li>
          <button @click="$modal('show', 'feedback');" class="btn btn-sm btn-primary btn-sm rounded-5">Обратная связь
          </button>
        </li>
        <li v-if="show_pwa">
          <a href="" v-on:click.prevent="installPWA" title="Установить приложение" class="nav-link text-white">
            <i class="bi bi-box-arrow-down"></i>
          </a>
        </li>
        <!--        <li :class="`alert_count${has_alert?' active':''}`">-->
        <!--          <a href="" v-on:click.prevent="setAlertRead" title="Уведомления" data-bs-toggle="offcanvas"-->
        <!--             data-bs-target="#alertArea" aria-controls="alertArea" class="nav-link text-white">-->
        <!--            <i class="bi bi-bell"></i>-->
        <!--            {{ alert_count }}-->
        <!--          </a>-->
        <!--        </li>-->
        <li class="user-info">
          <router-link to="/settings/" title="Личный кабинет" class="nav-link text-white">
            <i class="bi bi-person-circle"></i>
            {{ user.fullname }}
          </router-link>
        </li>
        <li class="logout">

          <a href="#" @click.prevent="logout" title="Выход" class="no-wrap btn btn-outline-primary btn-sm rounded-5">
            Выход
          </a>
        </li>
      </ul>
      <button class="navbar-toggler d-md-none collapsed text-white" type="button"
              @click="$store.dispatch('toggle_sidebar')">
        <i v-if="!$store.getters.sidebar_state" class="bi bi-list"></i>
        <i v-else class="bi bi-x-lg"></i>
      </button>

      <!--      <div class="offcanvas offcanvas-end" tabindex="-1" id="alertArea" aria-labelledby="offcanvasRightLabel">-->
      <!--        <div class="offcanvas-header">-->
      <!--          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>-->
      <!--        </div>-->
      <!--        <div class="offcanvas-body">-->
      <!--          <template v-if="$store.getters.alerts.length">-->
      <!--            <div class="show alerts" role="alert" aria-live="assertive" aria-atomic="true">-->
      <!--              <div v-for="alert in $store.getters.alerts" :class="`mb-3 ${alert.read?'disabled':''}`">-->
      <!--                <div :class="`toast-header mb-2 border-${alert.color} bg-gradient p-1`">-->

      <!--                  <p class="me-auto" v-html="alert.text"></p>-->
      <!--                  <small>{{ alert.date_create }}</small>-->
      <!--                </div>-->

      <!--              </div>-->
      <!--            </div>-->

      <!--          </template>-->
      <!--          <template v-else>-->
      <!--            <div class="show">-->
      <!--              <div class="toast-header mb-2">-->
      <!--                <p class="text-dark">Нет новых уведомлений</p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </template>-->
      <!--        </div>-->
      <!--      </div>-->
    </template>

    <modal modal_id="feedback">
      <template v-slot:header>
        <h1>Обратная связь</h1>
      </template>
      <template v-slot:body>
        <form action="." @submit.prevent="sendFeedBack()">
          <div>
            <textarea required class="form-control text-black" name="" id="" cols="30" rows="30" v-model="message"
                      placeholder="Введите ваше сообщение"></textarea>
            <button style="display: none" ref="feed_form" type="submit"></button>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="$modal('hide', 'feedback');"
                data-bs-dismiss="modal"> Отмена
        </button>
        <button type="button" class="btn btn-primary" v-on:click.prevent="$refs['feed_form'].click()">Отправить
        </button>
      </template>

    </modal>
    <modal modal_id="success_feedback">
      <template v-slot:header>
      </template>
      <template v-slot:body>
        <h3>Спасибо. Ваше обращение принято в обработку</h3>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="$modal('hide', 'success_feedback');"
                data-bs-dismiss="modal"> Закрыть
        </button>
      </template>

    </modal>

  </header>
</template>

<script>
import {UserApi} from '@/api/user'

export default {
  name: 'Header',
  data() {
    return {
      open_menu: false,
      open_user: false,
      project_name: '',
      has_alert: false,
      est_name: '',
      curr_project: false,
      show_pwa: false,
      message: ""
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    alert_count() {
      return this.$store.getters.alerts.filter((alert) => {
        return !alert.user || !alert.read
      }).length
    }
  },
  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.show_pwa = true
    })
  },
  watch: {
    alert_count() {
      let $this = this;

      $this.has_alert = true;
      setTimeout(function () {
        $this.has_alert = false;
      }, 500);
    }
  },
  methods: {
    installPWA() {
      this.installEvent.prompt()
    },
    logout: function () {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
    },
    setAlertRead: function () {
      UserApi.read_alert().then(() => this.$store.dispatch('alert_list'))
    },
    sendFeedBack: function () {
      UserApi.feedback({message: this.message}).then(() => {
        this.$modal('hide', 'feedback');
        this.$modal('show', 'success_feedback');
      })
    }
  }
}
</script>
<style scoped lang="scss">
textarea {
  height: 237px;
  max-height: 90%;

  &::placeholder {
    color: #000;
  }
}

</style>

